<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','icon']"
            ref="list"
        >
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加分类</a-button>
            </template>

            <template slot="icon" slot-scope="data">
                <img style="width:20px; height:20px" :src="data.text" alt />
            </template>

            <template slot="operation" slot-scope="data">
                <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
            </template>
        </TableList>

        <EditPop
            ref="edit_pop"
            @handleSubmit="submit"
            :form_data="form_data"
            :visible.sync="visible"
        ></EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import TableList from "@/components/TableList";
import { getCateList, changeCate, getCate, delCate } from "@/api/notice";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "分类图标",
        dataIndex: "icon",
        scopedSlots: {
            customRender: "icon"
        }
    },
    {
        title: "公告分类",
        dataIndex: "name",
    },
    {
        title: "备注",
        dataIndex: "remarks",
    },
    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];


export default {
	name: "Index",
	components: {
        EditPop,
        TableList
    },
    data() {
        return {
            get_table_list: getCateList,
            columns,
			visible: false,
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'upload',
                        name: 'icon',
                        title: '分类图标',
                        options: {

                        },
                    },
                    {
                        type: 'text',
                        name: 'name',
                        title: '公告分类',
                        options: {},
                    },
                    {
                        type: 'textarea',
                        name: 'remarks',
                        title: '备注',
                        options: {},
                    },
                ]
            }
        };
    },

    
    async created() {
        
    },
    methods: {
        add() {
			this.form_data.title = "添加"
            this.visible = true;
            this.form_data.list.forEach(item => {
                item.options.initialValue = "";
                if (item.type == 'upload') {
                    item.options.initialValue = [];
                }
            })
        },
        edit(record) {
			this.form_data.title = "编辑"
            getCate({
                data: {
                    id: record.id
                }
            }).then(res => {
                let data = res.data
                this.form_data.list.forEach(item => {


                    if (item.type == 'upload') {
                        let val = {};
                        val[item.name] = {
                            fileList: [{
                                url: data[item.name]
                            }]
                        }
                        item.options.initialValue = [{
                            uid: data[item.name],
                            name: data[item.name],
                            status: 'done',
                            url: data[item.name]
                        }];
                    } else {
                        item.options.initialValue = data[item.name];
                    }
                })
                this.visible = true;

                this.form_data.list.forEach(item => {

                })

            })
        },
        del(record) {
            delCate({
                data: {
                    id: record.id
				},
				info:true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },        
        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }

            changeCate({
                data: {
                    ...data
				},
				info:true,
            }).then(res => {

                this.visible = false;
                this.$refs.list.get_list();
            })

        },

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>